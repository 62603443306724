var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('public-donation-info'),(_vm.getCampaignType === 'district' && !_vm.getDonationPageType)?_c('school-detail'):_vm._e(),(
      _vm.getDonationPageType.type === 'student' &&
      this.$route.matched[0].path.substring(1) !== 'parent'
    )?_c('wizfit-video-progress'):_vm._e(),(this.$route.matched[0].path.substring(1) === 'parent')?_c('student-card-goal'):_vm._e(),(_vm.getDonationPageType.type === 'student' )?_c('prizes-won'):_vm._e(),(_vm.getDonationPageType.type === 'student' )?_c('video-section'):_vm._e(),(this.$route.matched[0].path.substring(1) === 'parent')?_c('student-card'):_vm._e(),(this.$route.matched[0].path.substring(1) === 'parent')?_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('intro-video-pdp')],1)],1)],1):_vm._e(),(
      _vm.getDonationPageType.type === 'student' ||
      _vm.getDonationPageType.type === 'teacher'
    )?_c('recent-donation',{staticStyle:{"margin-left":"7%"}}):_vm._e(),_c('sponsors-detail'),_c('wizfit-gallery'),(![177, 178, 179, 180].includes(this.getCampaign.campaign_detail.school))?_c('leaderboard-table'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }